<template>
  <div>
    <header class="page-header">
      <PageTitle/>
      <div class="buttons">
        <button class="btn btn-download" @click="exportOverview">Exporteer overzicht</button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <div class="filters">
          <h4>{{ $t('Filters') }}</h4>
          <formulate-form v-model="filters">
            <formulate-input :label="$t('Vanaf')" @change="saveFilters" type="date" name="from_date"></formulate-input>
            <formulate-input :label="$t('Tot')" @change="saveFilters" type="date" name="to_date"></formulate-input>
            <formulate-input :value="'slot'" :label="$t('Sorteer op')" @change="saveFilters" type="select" :options="{'created': $t('Aangemaakt'), 'slot': $t('Datum afspraak')}" name="order_by"></formulate-input>
            <formulate-input @change="saveFilters" type="select" :options="{'asc': $t('Oplopend'), 'desc': $t('Aflopend')}" name="order_dir"></formulate-input>
          </formulate-form>
        </div>
        <div class="table-wrapper" v-if="appointments.length > 0">
          <div class="table-element">
            <table class="appointments">
              <thead>
              <tr>
                <th class="id">{{ $t('ID') }}</th>
                <th>{{ $t('Naam') }}</th>
                <th>{{ $t('Datum') }}</th>
                <th>{{ $t('Aangemaakt') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr @click="openModal(appointment)" :key="appointment.id" @contextmenu.prevent.stop="appointmentRightClick($event, appointment)" v-for="appointment in appointments">
                <td class="id">{{ appointment.id }}</td>
                <td>{{ appointment.first_name + ' ' + appointment.last_name }}</td>
                <td>{{ appointment.slot.setLocale('nl').toFormat('dd LLLL yyyy T') }}</td>
                <td>{{ appointment.created.setLocale('nl').toFormat('dd/LL/yyyy') }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="pagination">
            <div class="results">
              {{ $t(`${pageTotal.min} - ${pageTotal.max} van ${total} afspraken`) }}
            </div>

            <div class="buttons">
              <button class="btn-inverse" :disabled="page === 0" @click="prevPage()">Prev</button>
              <button class="btn-inverse" :disabled="appointments.length !== perPage" @click="nextPage()">Next</button>
            </div>
          </div>
        </div>
        <div v-else>Geen resultaten</div>
      </div>
    </div>

    <Appointment @save="appointmentSaved" @close="open = false" :open="open" :appointment="activeAppointment"></Appointment>
    <vue-simple-context-menu element-id="vueSimpleContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu" @option-clicked="contextOptionClicked"></vue-simple-context-menu>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { ApiService } from '@/services/admin/api.service'
import Appointment from '@/components/admin/Appointment'
import Swal from 'sweetalert2'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle, Appointment },
  data: function () {
    return {
      activeAppointment: null,
      open: false,
      appointments: [],
      total: 0,
      page: 0,
      perPage: 25,
      filters: {
        from_date: DateTime.now().toISODate(),
        to_date: DateTime.now().plus({ months: 1 }).toISODate()
      },
      contextMenuOptions: [{ id: 'deleteAppointment', name: 'Afspraak verwijderen' }]
    }
  },
  async mounted () {
    await this.fetchAppointments()
  },
  methods: {
    async exportOverview () {
      await ApiService.exportAppointmentsOverview(this.filters).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'afspraken.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    async appointmentSaved () {
      await this.fetchAppointments()
    },
    openModal (appointment) {
      this.activeAppointment = appointment
      this.open = true
    },
    async saveFilters () {
      await this.fetchAppointments()
    },
    appointmentRightClick: async function (event, appointment) {
      if (this.checkPermissions(['appointment.delete'])) {
        this.$refs.vueSimpleContextMenu.showMenu(event, appointment)
      }
    },
    contextOptionClicked: async function (event) {
      await this.deleteAppointment(event.item.id)
    },
    deleteAppointment: async function (id) {
      if (!this.checkPermissions(['appointment.delete'])) {
        return false
      }

      Swal.fire({
        title: 'Ben je zeker dat je deze afspraak wilt verwijderen?',
        text: 'Deze actie kan niet ongedaan worden gemaakt!',
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: 'Verwijderen',
        cancelButtonText: 'Annuleren'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await ApiService.deleteAppointment(id)

          if (response.status === 200) {
            await this.fetchAppointments()
          }
        }
      })
    },
    prevPage: async function () {
      if (this.page > 0) {
        this.page--
        await this.fetchAppointments()
      }
    },
    nextPage: async function () {
      if (this.appointments.length === this.perPage) {
        this.page++
        await this.fetchAppointments()
      }
    },
    async fetchAppointments () {
      this.filters.page = this.page
      const response = await ApiService.fetchAppointmentsOverview(this.filters)
      this.appointments = []

      response.data.appointments.forEach((appointment, i) => {
        const appointmentData = {}

        for (const prop in appointment) {
          if ((prop === 'created' || prop === 'slot') && appointment[prop] != null) {
            appointmentData[prop] = DateTime.fromISO(appointment[prop])
          } else {
            appointmentData[prop] = appointment[prop]
          }
        }

        this.appointments.push(appointmentData)
      })

      this.total = response.data.total
    }
  },
  computed: {
    pageTotal: function () {
      let offsetMax = this.perPage * (this.page + 1)
      const offsetMin = offsetMax - this.perPage + 1

      if (offsetMax > this.total) {
        offsetMax = this.total
      }

      return { min: offsetMin, max: offsetMax }
    }
  }
}
</script>
